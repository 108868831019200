// imports
@import 'node_modules/frontend-core/src/styles/custom';

$dark-green: #52bf87;

.supplierIntegrationsOverviewWrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-left: 2em;
}

.contractsOverviewWrapper {
    width: 95vw;
    margin-left: -10vw;

    .contractHeader {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: flex-start;
        padding-left: 13vw;
        padding-bottom: 1em;
    }
}

.title {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-bottom: 1em;

    :first-child {
        padding-right: 0.2em;

        :hover {
            cursor: pointer;
        }
    }
}

.headerClass {
    cursor: pointer;

    :focus {
        outline: none;
    }
}

.integrationNameHeader {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.withoutSearch {
}

.headerContent {
    display: flex;
    flex-direction: row;
}

.createIntegrationButton {
    display: flex;
    background: $dark-green;
    padding: 0.8em;
    border-radius: $border-radius;
    margin: -1em 0 0 auto;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    font-family: $headings-font-family;
}
