// imports
@import 'node_modules/frontend-core/src/styles/custom';

.supplierPageWrapper {
    display: flex;
    flex-direction: column;
}
.headerWrapper {
    display: flex;
    flex-direction: row;
}

.divider {
    margin-top: 0;
    margin-bottom: 1em;
}

.infoWrapper {
    display: flex;
    align-self: center;
    justify-content: center;
}
