// imports
@import 'node_modules/frontend-core/src/styles/custom';

.supplierHeaderWrapper {
    display: flex;
    width: 100%;
    //justify-content: flex-start;
    padding-left: 2em;
    margin-bottom: -1em;

    p:last-child {
        margin-top: -1em;
    }
}

.supplierNumberClass {
    padding-top: 1em;

    a {
        text-decoration: none;
        color: $link-color;
        margin-left: 0.8em;
    }
}

.center {
    display: flex;
    align-self: flex-end;
    padding-left: 1em;
    padding-right: 2em;

    .navBar {
        color: $text-color;
        font-weight: bold;
        font-size: 20px;
    }

    .contractsItem {
        margin-left: 1em;
    }
}

.navButtons {
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding-right: 2em;
}
