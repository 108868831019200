@import 'node_modules/frontend-core/src/styles/custom';

$grey: #d1d1d1;
$text-color: black;
$dark-red: #970610;

.infoModal {
    width: 40% !important;
    top: 5%;
    left: 30%;
    font-size: 18px;
    font-weight: 400;
}

.buttons {
    :first-child {
        color: $text-color;
        background: $grey;
    }

    :last-child {
        background-color: $red;
    }
    :last-child:hover {
        background: $dark-red;
    }
}

.cancelButton {
    color: $text-color;
    background: $grey;

    .cancelButton:hover {
        background: $dark-red;
    }
}

.goButton {
    color: $text-color;
    background: $green;

    .goButton:hover {
        background: #b2e5ad;
    }
}
