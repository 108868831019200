// imports
@import 'node_modules/frontend-core/src/styles/custom';

body {
    margin: 0;
    font-family: $font-family-sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App {
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    width: 100vw;
    margin: auto;
    padding: 0;
}

.main {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-items: center;
    width: 80vw;
    height: auto;
    padding: 1em 2em 8em;
    margin-top: 1vh;
}
