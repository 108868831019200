@import 'node_modules/frontend-core/src/styles/custom';

.createIntegrationWrapper {
    display: flex;
    background: $green;

    :hover {
        background: #b2e5ad;
    }
}

.icon {
    height: 40px;
    width: auto;
    padding-left: 0.5em;
    margin-top: -0.25em;
}
