// imports
@import '../../../../node_modules/frontend-core/src/styles/custom';

$dark-green: #52bf87;

.createIntegrationForm {
    display: flex;
    width: 50em;
    padding-top: 3em;
}

.integrationType {
    display: flex;
    flex-direction: row;
    align-items: center;

    .integrationTypeHeader {
        margin-right: 0.5rem;
        margin-bottom: 1rem;
    }
}

.integrationMarketplace {
    display: flex;
    flex-direction: row;
    align-items: center;

    .integrationMarketplaceHeader {
        margin-right: 0.5rem;
        margin-bottom: 1rem;
    }
}

.createButton {
    Button {
        padding: 0.6em 1em;
        font-weight: 500;
        font-size: 18px;
        background: $dark-green !important;
    }
}

.result {
    margin-left: auto;
}

.integrationPlatform {
    display: flex;
    flex-direction: row;
    align-items: center;

    .integrationPlatformHeader {
        margin-right: 0.5rem;
        margin-bottom: 1rem;
    }
}

.setAsActive {
    display: flex;
    flex-direction: column;
    margin-top: 1em;

    .isActiveRes {
        margin-left: auto;
        margin-top: -4em;
        margin-bottom: 2em;
    }
}

.label {
    font-weight: bold;
    font-size: 16px;
}

.configClass {
    padding-top: 2em;
}
