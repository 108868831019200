.supplierInfoWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: center;
    min-height: 20em;
    height: auto;
    width: 60vw;
    padding: 0 1em 1em;
    margin-left: -6em;

    .headerWrapper {
        display: flex;
        height: 2em;
        width: 100%;
    }
}

.supplierInfoContent {
    display: flex;
    flex-direction: row;
    gap: 8em;
}

.supplierInfoLeft {
    display: flex;
    flex-direction: column;
}

.contactsWrapper {
    display: flex;
    flex-direction: row;
    padding-bottom: 2em;
}

.infoWrapper {
    padding-bottom: 2em;
}

.activeContractWrapper {
    //padding-top: 2em;
    //padding-left: 4em;
}

.contactWrapper {
    padding-bottom: 2em;
}

.infoContent {
}

.addressContent {
}

.content {
    display: flex;
    flex-direction: row;
}

.infoLabels {
    display: flex;
    flex-direction: column;
    padding-right: 4em;
}

.addressLabels {
    display: flex;
    flex-direction: column;
    padding-right: 5.3em;
}

.activeContractLabels {
    display: flex;
    flex-direction: column;
    padding-right: 5em;
}

.contactsLabels {
    display: flex;
    flex-direction: column;
    padding-right: 3em;
}

.data {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
}

.label {
    font-size: 15px;
    font-weight: 400;
    padding: 3px;
}

.contactLabel {
    font-size: 15px;
    font-weight: 400;
    padding: 3px;
}

.infoData {
    font-size: 16px;
    font-weight: 600;
    padding: 4px 3px 3px;
    margin-top: -2px;
}

.contractData {
    font-size: 16px;
    font-weight: 600;
    padding: 4px 0 3px 0;
    margin-top: -2px;
}

.infoHorizontal {
    margin-top: -0.1%;
    margin-bottom: -0.1%;
    padding-right: 280%;
}

.addressHorizontal {
    width: 400%;
    margin-top: -0.1%;
    margin-bottom: -0.1%;
}

.activeContractHorizontal {
    width: 360%;
    margin-top: -0.1%;
    margin-bottom: -0.1%;
}

.contactHorizontal {
    width: 250%;
    margin-top: -0.1%;
    margin-bottom: -0.1%;
}
