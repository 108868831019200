// imports
@import 'node_modules/frontend-core/src/styles/custom';

$dark-red: #970610;

.integrationFormWrapper {
    display: flex;
    flex-direction: column;
    align-self: center;
    padding: 2em;
}

// headers
.integrationFormHeader {
    display: flex;
    justify-content: center;
    height: 2em;
    width: 100%;
}

.integrationFormSubHeader {
    display: flex;
    justify-content: center;
    height: 2em;
    padding-bottom: 3em;
}

.subFormTitle {
    padding-top: 2em;
}

// form
.integrationForm {
    display: flex;
    flex-direction: column;
    padding-bottom: 0.2em;
}

.integrationFormMagento {
    display: flex;
    flex-direction: column;
    padding-bottom: 0.2em;
}

.settingsWrapper {
    width: 70%;
    margin-left: 15%;
}

.topSettings {
    padding-left: 1em;
    padding-right: 1em;
}

.generalSettingsClass {
    padding-left: 1em;
    padding-right: 1em;
}

.mappingForm {
    padding-left: 1em;
    padding-right: 1em;
}

.middleTextFieldWrapper {
    display: flex;
    flex-direction: row;

    :last-child {
        margin-left: auto;
    }
}

.fieldButtons {
    display: flex;
    flex-direction: row;

    :first-child {
        margin-right: 1em;
    }
}

.mappingWrapper {
    align-self: center;
}

// labels
.viewLabels {
    font-weight: 650;
}

.labels {
    font-weight: 450;
}

.fieldLabel {
    margin-right: 0.5em;
    padding-bottom: 1em;
}

.stockLevel {
    padding-top: 0.4em;
}

.mappingLabels {
    font-size: 18px;
    font-weight: 500;
    color: white;
}

// control buttons
.controlButtonsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 2em;

    :first-child {
        margin-right: 1em;
    }
    .cancelButton {
        background: gray;
    }

    :last-child {
        margin-left: 1em;
        background-color: $red;
    }
    :last-child:hover {
        background: $dark-red;
    }
}

.operatorButton {
    height: 2.5em;
    width: 2.5em;
}

.isActiveClass {
    width: 4em;
    padding-left: 1em;
    border-radius: $border-radius;
}

.dateInfo {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 4em;
    padding-bottom: 2em;
    align-items: center;
    justify-content: center;

    .dateStringClass {
        font-size: 16px;
        font-weight: 600;
    }
}
