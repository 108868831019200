// imports
@import 'node_modules/frontend-core/src/styles/custom';

$lightRed: #ffa6a3;

.header {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    padding-left: 2em;
    padding-bottom: 1em;
}

.title {
    padding-bottom: 1em;
}

// hacky hack
.searchField {
    color: white;
    margin-top: -1.5em;

    button {
        background: $pink;
        margin-left: 1em;
        margin-top: -0.2em;
    }
}

// headers
.supplierNameHeader {
    width: 15em;
}

.supplierNameCol {
    cursor: pointer;
}

.headerClass {
    cursor: pointer;

    :focus {
        outline: none;
    }
}

.onHover {
    :hover {
        cursor: pointer;
    }
}
